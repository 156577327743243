import React, { useEffect } from "react"

import { Trans } from "react-i18next"
import EventCard from "./EventCard"
import { IEvent } from "../../libs/interfaces"
import { setupSlider } from "../../services/setupSlider"

interface IEventContainer {
  withSlider: boolean
}

function EventsContainer({ withSlider }: IEventContainer) {
  const eventList: IEvent[] = [
    {
      imgUrl: "/assets/events/hip.png",
      title: "Lyon Hip 2023 Arthroplasty",
      dateString: "Jeudi 7 – Vendredi 8 sept. 2023",
      location: `Centre de Congrès
      50, quai Charles de Gaulle
      Lyon, France`,
      language: "Français",
      link: "https://lha-congress.com/programme.html",
    },
    {
      imgUrl: "/assets/events/SOFCOT.png",
      title: "97ème Congrès de la SOFCOT",
      dateString: "Lundi 13 – Mercredi 15 nov. 2023",
      location: `Palais des Congrès
      Paris, France`,
      language: "Français",
      link: "https://www.sofcot-congres.fr/fr/programme-sofcot/en-detail/complet",
    },
    {
      imgUrl: "/assets/events/sfa.png",
      title: "Congrès annuel de la SFA",
      dateString: "Jeudi 14 – Samedi 16 déc. 2023",
      location: `Centre de Congrès
50, quai Charles de Gaulle
Lyon, France`,
      language: "Français",
      link: "https://congres.sofarthro.com/programme/",
    },
  ]

  if (withSlider) {
    useEffect(() => {
      setTimeout(() => {
        setupSlider(".slider-events")
      }, 300)
    }, [withSlider])
  }

  return (
    <section className="relative pb-16">
      <div className="container relative">
        <div className="grid grid-cols-1 text-center">
          <h3 className="h2 my-12 text-2xl font-Montserrat font-bold md:text-3xl text-left">
            <Trans>EVENTS.HEAD</Trans>
          </h3>
        </div>
      </div>
      <div className="container" id="eventContainer">
        {withSlider && (
          <div className="slider-events mt-8">
            {eventList.map((event, index) => {
              return <EventCard event={event} />
            })}
          </div>
        )}
        {!withSlider && (
          <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 lg:grid-cols-3">
            {eventList.map((event, index) => {
              return <EventCard event={event} index={index} />
            })}
          </div>
        )}
      </div>
    </section>
  )
}

export default EventsContainer
