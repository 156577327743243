import React from "react"
import { Trans } from "react-i18next"
function Career() {
  return (
    <section className="relative pt-16">
      <div className="bg-primary-50 z-0 dark:bg-slate-700">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <img
            src="/assets/career/Shutterstock_2115699686.png"
            alt="digikare"
            className="align-middle absolute z-1 opacity-25 md:relative md:z-1 md:opacity-100 lg:max-w-[650px]"
          />
          <div className="col-span-2 text-center z-2 md:mt-14">
            <h3 className="pt-10 md:mb-12 font-bold text-3xl md:text-5xl ">
              <Trans>CAREER.HEAD</Trans>
            </h3>
            <h4 className="pt-10 md:mb-12 font-medium text-2xl  md:text-4xl text-center">
              <Trans>CAREER.SUBTITLE</Trans>
            </h4>
            <h5 className="pt-12 font-light text-xl  md:text-2xl text-center">
              <Trans>CAREER.TEXT_P1</Trans>
            </h5>
            <h5 className="font-light text-xl  md:text-2xl text-center">
              <Trans>CAREER.TEXT_P2</Trans>
            </h5>
            <div className="btn-container pt-8 mb-4 md:pt-16 md:mb-0 text-center">
              <a
                href="/team"
                className="btn btn-primary rounded-md w-52 h-16 text-xl pt-4"
                aria-label="Apply Button"
              >
                <Trans>CAREER.BTN_CONTENT</Trans>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Career
