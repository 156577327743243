import React from "react"
import { Trans } from "react-i18next"
function Renacot() {
  return (
    <section className="renacot relative py-16">
      <div className="container">
        <div className="grid grid-cols-2 gap-[30px] align-middle">
          <div className="logo flex justify-center align-middle">
            <img
              src="/assets/logos/logo-digikare.svg"
              alt="digikare"
              className="align-middle"
            />
          </div>
          <div className="logo flex justify-center align-middle">
            <img
              src="/assets/logos/logo-renacot.png"
              alt="renacot"
              className="align-middle"
            />
          </div>
        </div>
        <h3 className="text-l text-center font-medium md:text-2xl ">
          <Trans>RENACOT.HONOR</Trans>
        </h3>
        <h3 className="text-l text-center font-medium md:text-2xl ">
          <Trans>RENACOT.GRATEFULL</Trans>
        </h3>
      </div>
    </section>
  )
}

export default Renacot
