import { IEmployee, IService } from "../../libs/interfaces"
import React from "react"
import { Trans } from "react-i18next"

function ServiceCard({ service }: IService) {
  return (
    <div
      className="productContainerServices -mt-20 max-h-[300px] transition-all duration-500 ease-in-out hover:-translate-y-2 md:-mt-8 "
      key={service}
    >
      <div className="productCardServices w-[300px] h-[250px] md:w-[350px] md:h-[300px] mt-16 mx-auto md:mt-8 relative rounded-[2.5rem] bg-primary-50 p-1 text-start dark:bg-slate-700">
        <div className="content">
          <h4 className="title h3 mt-4 md:mt-6 px-4 mb-1 text-center text-lg md:text-xl font-bold hover:text-primary-600">
            <Trans>SERVICES.CARD_TITLE.{service}</Trans>
          </h4>
          <h5 className="title h4 mt-4 md:mt-6 px-4 text-left text-sm md:text-base font-bold text-primary-600">
            <Trans>SERVICES.CARD_FOCUS.{service}</Trans>
          </h5>
          <p className="mt-4 md:mt-6 px-4 max-h-36 overflow-y-auto text-justify text-xs md:text-sm text-slate-500 dark:text-slate-300">
            <Trans>SERVICES.CARD_TEXT.{service}</Trans>
          </p>
        </div>
      </div>
    </div>
  )
}

export default ServiceCard
