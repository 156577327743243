import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Seo from "../components/Seo"
import IndexHeader from "../components/Index/IndexHeader"

import { IProduct } from "../libs/interfaces"
import MissionDescription from "../components/mission/MissionDescription"
import Career from "../components/career/Career"
import ProductsContainer from "../components/Products/ProductsContainer"
import ServicesContainer from "../components/services/ServicesContainer"
import TrustUsContainer from "../components/trustUs/TrustUsContainer"
import Pictos from "../components/pictos/Pictos"
import Renacot from "../components/renacot/Renacot"
import EventsContainer from "../components/events/EventsContainer"

export default function index({ data }: any) {
  const { title, subtitle, header_img } = data.header.frontmatter

  const { desc_title, desc_text, desc_image } = data.mission.frontmatter

  const seo = {
    metaTitle: title,
    metaDescription: subtitle,
  }

  const products: IProduct[] = [
    ...data.productsList.nodes
      .map((node: any) => ({
        ...node.frontmatter,
        id: node.id,
        logo_dark: data.logos.nodes.find(
          (el: any) => el.name == node.frontmatter.logo_dark,
        ),
        logo_light: data.logos.nodes.find(
          (el: any) => el.name == node.frontmatter.logo_light,
        ),
      }))
      .filter((product: IProduct) => product.on_homepage === "yes"),
  ]

  const header_image = data.staticImg.nodes.find((el: any) => {
    return el.name == header_img
  })?.childrenImageSharp[0]

  const mission_desc_image = data.missionImg.nodes.find((el: any) => {
    return el.name == desc_image
  })?.childrenImageSharp[0]

  let with_slider = false
  if (typeof window !== "undefined") {
    with_slider = window.screen.width <= 768
  }

  // @ts-ignore
  return (
    <Layout navLight={false}>
      <Seo seo={seo} lang={data.locales.edges[0].node.language} />
      <IndexHeader title={title} subtitle={subtitle} img={header_image} />
      <MissionDescription
        title={desc_title}
        text={desc_text}
        desc_image={mission_desc_image}
      />{" "}
      <ProductsContainer products={products} withSlider={with_slider} />
      <Pictos />
      <Renacot />
      <ServicesContainer withSlider={with_slider} />
      <TrustUsContainer withSlider={with_slider} />
      <EventsContainer withSlider={with_slider} />
      <Career />
    </Layout>
  )
}

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        header: markdownRemark(
            frontmatter: { slug: { eq: "index/static" }, lang: { eq: $language } }
        ) {
            id
            frontmatter {
                lang
                slug
                title
                subtitle
                header_img
                video
                video_title
                video_subtitle
                video_bg_image
                twitterFeed_title
                twitterFeed_subtitle
                features {
                    title
                    text
                    logo
                }
            }
        }
        staticImg: allFile(
            filter: {
                extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
                relativePath: { glob: "assets/index/*" }
            }
        ) {
            nodes {
                name
                childrenImageSharp {
                    gatsbyImageData
                }
            }
        }
        mission: markdownRemark(
            frontmatter: { slug: { eq: "mission/static" }, lang: { eq: $language } }
        ) {
            id
            frontmatter {
                lang
                slug
                title
                subtitle
                desc_title
                desc_text
                desc_image
            }
        }
        missionImg: allFile(
            filter: {
                extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
                relativePath: { glob: "assets/teams/*" }
            }
        ) {
            nodes {
                name
                childrenImageSharp {
                    gatsbyImageData
                }
            }
        }
        productsStatic: markdownRemark(
            frontmatter: { slug: { eq: "products/static" }, lang: { eq: $language } }
        ) {
            frontmatter {
                title
                subtitle
            }
        }
        productsList: allMarkdownRemark(
            filter: {
                frontmatter: { slug: { glob: "products/*/*" }, lang: { eq: $language } }
            }
        ) {
            nodes {
                html
                id
                frontmatter {
                    slug
                    name
                    description
                    on_homepage
                    logo_light
                    logo_dark
                }
            }
        }
        logos: allFile(
            filter: {
                extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)|(svg)/" }
                relativePath: { glob: "assets/logos/*" }
            }
        ) {
            nodes {
                name
                childrenImageSharp {
                    gatsbyImageData
                }
                relativePath
                extension
            }
        }
    }
`
