import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { Trans, useTranslation } from "react-i18next"

function IndexHeader({
  title,
  subtitle,
  img,
}: {
  title: string
  subtitle: string
  img: IGatsbyImageData
}) {
  const { t } = useTranslation()

  const image = getImage(img)
  return (
    <div>
      <section className="startup-wrapper relative flex overflow-hidden pt-24 ">
        <div className="container mx-0 w-max h-max pr-32 sm:pr-0">
          <div className="mt-10 grid items-center gap-[30px] ">
            <div className="md:col-span-7">
              <div className="md:mr-6 md:mt-30 lg:mt-40">
                <h4 className=" text-4xl font-Montserrat font-bold leading-normal lg:text-5xl lg:leading-normal">
                  {title.substring(0, title.lastIndexOf("mieux"))}
                </h4>
                <h4 className="mb-5 text-4xl font-Montserrat font-bold leading-normal text-primary-600 dark:text-primary-600 lg:text-5xl lg:leading-normal">
                  {title.substring(title.lastIndexOf("mieux"))}
                </h4>
                <p
                  className="max-w-xl text-lg md:text-lg font-medium text-slate-500 dark:text-slate-300"
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                ></p>

              </div>
            </div>
          </div>
        </div>

        <div className="bg-index-header z-50 self-end">
          {image && (
            <GatsbyImage
              className="w-full"
              image={image}
              alt="digikare-bg-header"
            />
          )}
        </div>
      </section>
      <div className="container relative">
        <div className="grid grid-cols-1 text-center">
          <h3 className="mt-16 text-xl md:text-2xl lg:text-3xl font-medium font-Montserrat uppercase">
           <Trans>HEADER.punchLine</Trans>
          </h3> 
        </div>
      </div>
    </div>
  )
}

export default IndexHeader
