import React from "react"

import { Trans } from "react-i18next"
import { IEvent } from "../../libs/interfaces"

function EventCard({ event, index }: { event: IEvent; index?: number }) {
  return (
    <div
      className={`eventCardContainer p-0 ${
        (index || index === 0) && (index + 1) % 3 !== 0
          ? "md:border-r md:border-solid md:border-[#29A88E]"
          : ""
      }`}
    >
      <div
        className="eventCard transition-all duration-500 ease-in-out hover:-translate-y-2 "
        key={event.title}
      >
        <div className="content mt-7">
          <div className="img-container">
            <img src={event.imgUrl} alt={event.title} />
          </div>
          <h4 className="title h3 mb-2 text-lg font-medium hover:text-primary-600">
            {event.title}
          </h4>

          <p className="mt-3 text-slate-500 dark:text-slate-300">
            <i className="mdi mdi-calendar"></i>
            {event.dateString}
          </p>
          <p className="mt-3 text-slate-500 dark:text-slate-300">
            <i className="mdi mdi-map-marker"></i>
            {event.location}
          </p>
          <p className="mt-3 text-slate-500 dark:text-slate-300">
            <i className="mdi mdi-comment-text"></i>
            {event.language}
          </p>
          <div className="btn-container relative mt-6 text-center md:absolute">
            {event.link && (
              <a
                href={event.link}
                target="_blank"
                className="btn btn-primary mr-2 mt-2 rounded-md"
                aria-label="Apply Button"
                rel="noreferrer"
              >
                <Trans>EVENTS.BUTTON</Trans>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventCard
