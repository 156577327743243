import React, { useEffect } from "react"

import { Trans } from "react-i18next"
import ServiceCard from "./ServiceCard"
import { setupSlider } from "../../services/setupSlider"

interface IServices {
  withSlider: boolean
}

function ServicesContainer({ withSlider }: IServices) {
  const serviceList = [
    "ENGAGEMENT",
    "PATHWAY",
    "REGISTRY",
    "DATA",
    "CHANNEL",
    "PARTNERS",
  ]

  if (withSlider) {
    useEffect(() => {
      setTimeout(() => {
        // @ts-ignore
        setupSlider(".slider-services")
      }, 300)
    }, [withSlider])
  }

  return (
    <section className="relative py-8">
      <div className="container relative">
        <div className="grid grid-cols-1 text-center">
          <h3 className=" h2 center-align my-12 text-center text-2xl font-Montserrat md:text-3xl font-bold">
            <Trans>SERVICES.HEAD</Trans>
          </h3>
        </div>
      </div>
      <div className="container" id="servicesContainer">
        {withSlider && (
          <div className="slider-services mt-8">
            {serviceList.map((service) => {
              return <ServiceCard service={service}></ServiceCard>
            })}
          </div>
        )}
        {!withSlider && (
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-[60px] xl:grid-cols-3">
            {serviceList.map((service) => {
              return <ServiceCard service={service}></ServiceCard>
            })}
          </div>
        )}
      </div>
    </section>
  )
}

export default ServicesContainer
