import React from "react"
import { Trans } from "react-i18next"
function Pictos() {
  const pictos = ["LEADER", "OBSERVATION", "SOCIETE_SAVANTE"]
  return (
    <section className="relative py-16">
      <div className="container">
        <div className="grid grid-cols-1 gap-[10px] md:grid-cols-2 lg:grid-cols-3">
          {pictos.map((picto) => {
            return (
              <div className="picto" key={picto}>
                <img
                  src={"/assets/index/pictos/PICTO_" + picto + ".png"}
                  alt="{picto.description}"
                  className="max-w-[300px] lg:max-w-full m-auto"
                />
                <p className="text-slate-500 font-light font-Montserrat md:text-lg dark:text-slate-300">
                  <Trans>PICTOS.{picto}</Trans>
                </p>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Pictos
